import React from "react";
import { MDXProvider } from "@mdx-js/react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function DefaultPage({ children, ...props }) {
  console.log(props)
  return (
    <Layout>
      <SEO title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} />
      <MDXProvider>{children}</MDXProvider>
    </Layout>
  );
}

export default DefaultPage;
