const theme = {
  fonts:{
    body: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue'",
    heading: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen-Sans,Ubuntu,Cantarell,'Helvetica Neue'"
  },
  colors: {
    primary: "#68C087",
    secondary: "papayawhip"
  },
  space: ["0rem", "0.25rem", "0.5rem", "1rem", "2rem", "3rem"],
  breakpoints: ["40rem", "52rem", "64rem", "80rem"],
  fontSizes: ["0.875rem", "1rem", "1.25rem", "1.5rem", "1.75rem", "2rem", "2.5rem"]
};

export const mq = {
  sm: `@media screen and (min-width: ${theme.breakpoints[0]})`,
  md: `@media screen and (min-width: ${theme.breakpoints[1]})`,
  lg: `@media screen and (min-width: ${theme.breakpoints[2]})`,
  xl: `@media screen and (min-width: ${theme.breakpoints[3]})`
};

export default {
  ...theme,
  mq
};
