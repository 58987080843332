import React from "react";
// import { graphql, StaticQuery } from "gatsby";
// import Img from "gatsby-image";

export default function () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="300" height="auto" viewBox="0 0 509.16 105.42">
      <title>logo-horiz</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path fill="#2e3234" d="M35.59,12.12A45.21,45.21,0,0,1,41.74,8c1.65-1.06,3.22-2.27,5-3.19A65.9,65.9,0,0,0,53.54.44,1.78,1.78,0,0,1,56,.56c1.37,1,2.93,1.69,4.29,2.69,1.08.8,2.24,1.45,3.36,2.19C66,7,68.33,8.36,70.65,9.85q4.71,3,9.45,6c4.43,2.81,8.82,5.68,13.3,8.4.89.54,1.76,1.14,2.65,1.71a1.32,1.32,0,0,1,.64,1.23c0,3,0,6,0,8.94,0,.48.06.92-.63,1.12-4.26,1.22-8.45,2.69-12.75,3.8-.76.2-.86,0-.86-.65,0-1.76,0-3.52,0-5.28a1.47,1.47,0,0,0-.8-1.46c-4.81-3.05-9.59-6.13-14.4-9.19-3.85-2.45-7.73-4.86-11.56-7.35a1.44,1.44,0,0,0-1.81,0Q40.95,25.35,28,33.54a1.88,1.88,0,0,0-1,1.83c.06,5.23,0,10.47.05,15.7,0,.7-.16,1-.9,1.1-4.17.53-8.37.68-12.55,1.14-.95.1-.7-.57-.7-1q0-8.09,0-16.16V27.85a3.59,3.59,0,0,1,2.19-3.1,30.63,30.63,0,0,0,4.76-2.91c.47-.33.3-.74.3-1.12,0-5.08,0-10.16,0-15.25,0-.76.14-1,1-1q6.77.06,13.53,0c.82,0,1,.23,1,1C35.55,7.54,35.59,9.63,35.59,12.12Z" />
          <path fill="#40c381" d="M116.86,36.67c.18-.13.4-.32.53,0s-.18.26-.34.32Z" />
          <path fill="#40c381" d="M117.75,36.35a1.13,1.13,0,0,1-.2.09s-.07-.09-.1-.14.12-.09.18-.09S117.71,36.3,117.75,36.35Z" />
          <path fill="#40c381" d="M117.86,61.6l.06-.18.13.09-.17.11Z" />
          <path fill="#2e3234" d="M85.51,87.12c-7.34-3.22-13.94-6.13-20.57-9-16.22-7-13.44-4.87-20.22-20.23-2.89-6.54-5.63-13.14-8.42-19.68,1.09-1.05,1.82,0,2.57.29,8.76,3.77,17.5,7.6,26.26,11.39,3,1.32,5.63,3.24,7,6.27C76.54,66.16,80.82,76.24,85.51,87.12ZM65.3,62.05c-.33-2.81-1.85-4.43-4.65-4.47A4.17,4.17,0,0,0,56.05,62c0,2.85,1.64,4.77,4.77,4.65C63.68,66.54,65,64.77,65.3,62.05Z" />
          <path fill="#40c381" d="M76.47,54.22c.61,1.37,1.21,2.74,1.81,4.11,3.79-1.35,7.54-2.82,11.23-4.45a123.81,123.81,0,0,0,20.84-11.53,40.38,40.38,0,0,0,6.7-5.39l-.19-.29c-3.23,1.29-6.46,2.6-9.7,3.88C103.49,42,99.72,43.16,96,44.34c-3,.93-6.07,1.72-9.12,2.52s-6.18,1.64-9.31,2.31L73.64,50A15.1,15.1,0,0,1,76.47,54.22Z" />
          <path fill="#40c381" d="M1.17,71.29C4,71.23,6.81,71.15,9.64,71q11.1-.57,22.12-1.92,5.94-.74,11.83-1.77c-.36-.81-.73-1.68-1.14-2.64-.6-1.41-1.28-3-2.1-4.87q-.84-1.91-1.68-3.84c-4.41.58-8.84,1.1-13.27,1.62-5.91.7-11.84,1.13-17.76,1.69-2.13.21-4.27.26-6.4.33-.83,0-1.21.31-1.2,1.24C.1,64,.1,67.12,0,70.25,0,71.17.36,71.3,1.17,71.29Z" />
          <path fill="#40c381" d="M94.58,59.12C90.45,60.77,86.26,62.24,82,63.6L80.76,64c1,2.35,2,4.71,3.07,7.12q4.35-2,8.56-4.23a117.5,117.5,0,0,0,16.93-11A69,69,0,0,0,118.05,48c-.36-.2-.52,0-.75.18-3.51,2.11-7.14,4-10.83,5.79C102.57,55.84,98.6,57.52,94.58,59.12Z" />
          <path fill="#40c381" d="M76.75,88.49,66.18,83.86c-2.83.88-5.68,1.71-8.56,2.44-4.39,1.11-8.81,2.1-13.23,3-3.24.69-6.51,1.3-9.78,1.8-4.11.63-8.23,1.16-12.36,1.63-2.64.31-5.3.52-7.95.74-1.79.15-3.64.17-5.44.25-2.51.11-5,.06-7.52.09-1,0-1.33.24-1.34,1.31,0,3.06.43,6.12,0,9.18-.11.88.28,1.1,1.12,1.07,7.12-.27,14.23-.62,21.32-1.47,4.8-.57,9.59-1.24,14.36-2.08q7.44-1.31,14.74-3.2c4.37-1.14,8.7-2.46,13-3.92,4.74-1.61,9.39-3.45,14-5.48Z" />
          <path fill="#40c381" d="M116.82,61.94c-3.46,2.09-7,4-10.62,5.83-5,2.6-10.19,5-15.42,7.21-1.49.64-3,1.25-4.49,1.85,1,2.32,2,4.68,3,7.06a110.67,110.67,0,0,0,10.07-6.21c2.06-1.43,4.14-2.85,6.1-4.44,1.6-1.3,3.21-2.58,4.73-4,1.67-1.52,3.38-3,5-4.58a13.21,13.21,0,0,0,2.65-3.07l0,0C117.43,61.46,117.14,61.74,116.82,61.94Z" />
          <path fill="#40c381" d="M0,87.26c0,.84.13,1.19,1.12,1.17,5.45-.14,10.87-.56,16.3-1,3.22-.27,6.43-.55,9.64-1,4.47-.56,8.9-1.27,13.33-2,6-1,11.88-2.41,17.72-4-6.86-3-9.68-4.58-12-8.13q-2.94.54-5.89,1c-6.15.88-12.3,1.71-18.5,2.25-6.84.59-13.68,1.17-20.56,1.25-.87,0-1.18.2-1.16,1.13C.1,81,.08,84.13,0,87.26Z" />
          <path fill="#2e3234" d="M230.12,25.45c0-.73.31-1.1.91-1.1a1.64,1.64,0,0,1,1.31.9l9.82,14.45V25.33c0-.63.3-1,.9-1a.84.84,0,0,1,.93,1V41.91c0,.73-.3,1.11-.9,1.11a1.53,1.53,0,0,1-1.26-.75L232,27.87v14.2c0,.63-.3,1-.9,1a.84.84,0,0,1-.94-1Z" />
          <path fill="#2e3234" d="M268.11,33.56c0,4.86-2.72,9.71-8.13,9.71s-8-4.9-8-9.79,2.66-9.71,8-9.71S268.11,28.68,268.11,33.56Zm-14.2-.13c0,4,2.07,8,6.12,8s6.12-3.87,6.12-7.83-2-8-6.17-8S253.91,29.51,253.91,33.43Z" />
          <path fill="#2e3234" d="M275.82,25.38c0-.68.47-1,1.43-1H283c3.75,0,5.74,2.62,5.74,5.24a5,5,0,0,1-3.88,4.83l3.52,6.9a1.87,1.87,0,0,1,.23.88.74.74,0,0,1-.8.83.87.87,0,0,1-.86-.51l-4.05-7.7h-5.29v7c0,.75-.3,1.13-.9,1.13s-.93-.38-.93-1.13Zm1.83.83V33h5.59a3.29,3.29,0,0,0,3.62-3.32,3.43,3.43,0,0,0-3.62-3.47Z" />
          <path fill="#2e3234" d="M300.08,26.21h-3.7c-.68,0-1-.3-1-.91s.33-.93,1-.93h9.29a.84.84,0,0,1,1,.93c0,.61-.33.91-1,.91h-3.73V42q0,1.05-.9,1.05c-.63,0-1-.35-1-1.05Z" />
          <path fill="#2e3234" d="M313.78,25.28c0-.63.3-.93.9-.93a.81.81,0,0,1,.93.93v7.35H326V25.28a.81.81,0,0,1,.91-.93.83.83,0,0,1,.95.93V42.12c0,.6-.33.9-.95.9a.8.8,0,0,1-.91-.9V34.49h-10.4v7.63a.8.8,0,0,1-.9.9c-.63,0-.93-.3-.93-.9Z" />
          <path fill="#2e3234" d="M353.27,40.51l4.28-15.21a1.25,1.25,0,0,1,1.34-.95,1.21,1.21,0,0,1,1.3.95l4.16,15.18,2.51-15.15c.11-.68.43-1,1-1a1,1,0,0,1,1,1,.56.56,0,0,1,0,.22l-2.92,16.24A1.59,1.59,0,0,1,364.35,43a1.52,1.52,0,0,1-1.54-1.16l-4-14.57-4,14.17c-.3,1.13-1,1.61-1.66,1.61a1.69,1.69,0,0,1-1.58-1.48L348.77,25.5a.56.56,0,0,1,0-.22.92.92,0,0,1,1-.93c.55,0,.88.3,1,.93Z" />
          <path fill="#2e3234" d="M376.56,25.53c0-.76.35-1.13,1-1.13h7.65a.81.81,0,0,1,.93.93c0,.6-.3.9-.93.9H378.4v6.2H384a.84.84,0,0,1,.94,1c0,.58-.31.88-.94.88H378.4v6.92h7c.73,0,1.09.31,1.09.94s-.36.9-1.09.9h-7.85c-.9,0-1-.22-1-1.13Z" />
          <path fill="#2e3234" d="M399.37,25.93a3.2,3.2,0,0,0-3.2,3.15c0,1.61,2.16,2.54,4.3,3.7s4.68,2.47,4.68,5.08c0,3.6-2.69,5.46-6.16,5.46a7.83,7.83,0,0,1-4.33-1.25A1.34,1.34,0,0,1,394,41a.75.75,0,0,1,.86-.86,1.66,1.66,0,0,1,.88.3,6.61,6.61,0,0,0,3.34,1c2.19,0,4.06-1,4.06-3.37,0-1.51-1.82-2.47-3.78-3.5-2.47-1.28-5.23-2.69-5.23-5.31,0-3.3,2.51-5.21,5.66-5.21a9.07,9.07,0,0,1,4.28,1.16,1.32,1.32,0,0,1,.7,1.15.93.93,0,0,1-.88,1,2,2,0,0,1-.95-.46A7.47,7.47,0,0,0,399.37,25.93Z" />
          <path fill="#2e3234" d="M416.26,26.21h-3.7c-.68,0-1-.3-1-.91s.33-.93,1-.93h9.28a.84.84,0,0,1,1,.93c0,.61-.33.91-1,.91h-3.72V42c0,.7-.3,1.05-.91,1.05s-.95-.35-.95-1.05Z" />
          <path fill="#2e3234" d="M508.25,34.8h-79.1a.93.93,0,0,1,0-1.85h79.1a.93.93,0,0,1,0,1.85Z" />
          <path fill="#2e3234" d="M219.05,34.8H144.17a.93.93,0,1,1,0-1.85h74.88a.93.93,0,0,1,0,1.85Z" />
          <path fill="#40c381" d="M167.35,63a7.18,7.18,0,0,1-1.9,5.08,12,12,0,0,1-4.74,3.12,5.65,5.65,0,0,1,2.94,2.27l3.25,6.2a6.18,6.18,0,0,1,2.85,1.64l-.44,3.44h-7.53l-5.13-10.92c-.47-1.1-1-1.65-1.64-1.65h-2.9v7.13q3.25.71,4,1.74l-.44,3.7h-12l-.45-3.7a5.66,5.66,0,0,1,2.45-1.47V59.22a5.26,5.26,0,0,1-2.45-1.51l.45-3.7h11.36q6.24.09,9.27,2T167.35,63Zm-12.61-4.14h-2.63v8.46h2.54a8.81,8.81,0,0,0,4.52-.91,3.52,3.52,0,0,0,1.49-3.28,3.62,3.62,0,0,0-1.51-3.32A8.4,8.4,0,0,0,154.74,58.87Z" />
          <path fill="#40c381" d="M193,84.76H172.29l-.44-3.7a5.54,5.54,0,0,1,2.45-1.47V59.22a5.2,5.2,0,0,1-2.45-1.51l.44-3.7h20.32v7.75l-3.29.4a6.4,6.4,0,0,1-1.65-3.29h-6.95v7.66h6.19A5.41,5.41,0,0,1,188,64.66l2.81.31V72.9l-2.81.36a4.43,4.43,0,0,1-1.11-1.87h-6.19V79.9H188a6.6,6.6,0,0,1,1.65-3.3l3.3.4Z" />
          <path fill="#40c381" d="M203.66,68V79.68a5.42,5.42,0,0,1,2.45,1.64l-.44,3.44h-8.74l-.44-3.44a5.42,5.42,0,0,1,2.45-1.64V59.09a5.33,5.33,0,0,1-2.54-1.65l.45-3.43H206l8.87,17.29a29,29,0,0,1,1.92,5l.31,1.07.49-.18a41.54,41.54,0,0,1-.62-6.51V59.13a5.58,5.58,0,0,1-2.45-1.69L215,54h8.73l.45,3.43a5.58,5.58,0,0,1-2.45,1.69V84.76h-7l-9-17.43a28.58,28.58,0,0,1-1.92-5l-.31-1-.49.18A41.65,41.65,0,0,1,203.66,68Z" />
          <path fill="#40c381" d="M239,84.76H227.68l-.45-3.7a5.65,5.65,0,0,1,2.46-1.47V59.22a5.3,5.3,0,0,1-2.46-1.51l.45-3.7h9.94q7.17,0,11.38,4t4.21,11.79q0,7.77-3.81,11.36T239,84.76ZM236.1,79.9h3.21a7,7,0,0,0,5.28-2q1.93-2,1.94-7.86,0-11.15-8.42-11.14h-2Z" />
          <path fill="#40c381" d="M277.63,84.76H257l-.45-3.7A5.6,5.6,0,0,1,259,79.59V59.22a5.26,5.26,0,0,1-2.45-1.51L257,54h20.31v7.75l-3.29.4a6.4,6.4,0,0,1-1.65-3.29h-6.95v7.66h6.19a5.41,5.41,0,0,1,1.11-1.87l2.81.31V72.9l-2.81.36a4.43,4.43,0,0,1-1.11-1.87h-6.19V79.9h7.3a6.6,6.6,0,0,1,1.65-3.3l3.3.4Z" />
          <path fill="#40c381" d="M305.26,63a7.23,7.23,0,0,1-1.9,5.08,12,12,0,0,1-4.74,3.12,5.65,5.65,0,0,1,2.94,2.27l3.25,6.2a6.18,6.18,0,0,1,2.85,1.64l-.44,3.44h-7.53l-5.13-10.92q-.71-1.65-1.65-1.65H290v7.13c2.16.47,3.52,1.05,4.05,1.74l-.44,3.7h-12l-.45-3.7a5.6,5.6,0,0,1,2.45-1.47V59.22a5.26,5.26,0,0,1-2.45-1.51l.45-3.7H293q6.24.09,9.27,2T305.26,63Zm-12.61-4.14H290v8.46h2.54a8.84,8.84,0,0,0,4.52-.91,3.54,3.54,0,0,0,1.49-3.28,3.62,3.62,0,0,0-1.51-3.32A8.4,8.4,0,0,0,292.65,58.87Z" />
          <path fill="#2e3234" d="M334.13,58.87a12.22,12.22,0,0,0-3.16-.4,4.07,4.07,0,0,0-2.66.82,2.85,2.85,0,0,0-1,2.32,3.38,3.38,0,0,0,.76,2.34,6.52,6.52,0,0,0,3,1.47l4,1.16a11.17,11.17,0,0,1,5.1,2.91,8.1,8.1,0,0,1,1.9,5.82,8.68,8.68,0,0,1-3.28,7.17,12.52,12.52,0,0,1-8.13,2.63,23.14,23.14,0,0,1-5.5-.69,14.65,14.65,0,0,1-4.7-1.94v-1a17.49,17.49,0,0,1,.31-2.59,10.78,10.78,0,0,1,.89-3h4.23a9.22,9.22,0,0,1,.85,3.7,9.26,9.26,0,0,0,3.63.66,5.58,5.58,0,0,0,3.41-1,3.26,3.26,0,0,0,1.34-2.8,6.14,6.14,0,0,0-.14-1.41,2.6,2.6,0,0,0-.82-1.2,5.09,5.09,0,0,0-2-1l-4.58-1.33q-7-2-7-8.92a8,8,0,0,1,2.94-6.48,11.67,11.67,0,0,1,7.69-2.43,21.7,21.7,0,0,1,9.29,2.14v1a19.09,19.09,0,0,1-.31,2.58,10.83,10.83,0,0,1-.9,3H335A7,7,0,0,1,334.13,58.87Z" />
          <path fill="#2e3234" d="M361.58,84.76H351.15l-.44-3.7a5.54,5.54,0,0,1,2.45-1.47V74.46l-7.27-15.1A6.38,6.38,0,0,1,343,57.71l.4-3.48v0l.05-.18h10.42l.45,3.7A4.37,4.37,0,0,1,352.31,59l1.52,3.44a60.76,60.76,0,0,1,2.22,5.83l.32.89H357a55.81,55.81,0,0,1,2.45-6.72l1.43-3.26a5.8,5.8,0,0,1-2.41-1.51L359,54h10.16l.44,3.7a6.44,6.44,0,0,1-2.94,1.69l-7,15.06v5.13A6.39,6.39,0,0,1,362,81.06Z" />
          <path fill="#2e3234" d="M384.7,58.87a12.16,12.16,0,0,0-3.16-.4,4,4,0,0,0-2.65.82,2.83,2.83,0,0,0-1,2.32,3.38,3.38,0,0,0,.76,2.34,6.42,6.42,0,0,0,3,1.47l4,1.16a11.23,11.23,0,0,1,5.1,2.91,8.14,8.14,0,0,1,1.89,5.82,8.7,8.7,0,0,1-3.27,7.17,12.56,12.56,0,0,1-8.13,2.63,23.22,23.22,0,0,1-5.51-.69,14.75,14.75,0,0,1-4.7-1.94v-1a17.49,17.49,0,0,1,.31-2.59,11.13,11.13,0,0,1,.89-3h4.24a9.4,9.4,0,0,1,.84,3.7,9.26,9.26,0,0,0,3.63.66,5.56,5.56,0,0,0,3.41-1,3.23,3.23,0,0,0,1.34-2.8,6.21,6.21,0,0,0-.13-1.41,2.63,2.63,0,0,0-.83-1.2,5.11,5.11,0,0,0-2-1l-4.59-1.33q-7-2-7-8.92a8,8,0,0,1,2.95-6.48,11.66,11.66,0,0,1,7.68-2.43A21.67,21.67,0,0,1,391,55.79v1a17.55,17.55,0,0,1-.31,2.58,11.21,11.21,0,0,1-.89,3H385.6A6.9,6.9,0,0,1,384.7,58.87Z" />
          <path fill="#2e3234" d="M419.46,54.05v6.38l-2.81.35a4.36,4.36,0,0,1-1.11-1.87h-5.35V79.59a6.39,6.39,0,0,1,2.45,1.47l-.44,3.7H401.77l-.44-3.7a5.54,5.54,0,0,1,2.45-1.47V58.91h-5.35a4.26,4.26,0,0,1-1.12,1.87l-2.8-.35V54.05Z" />
          <path fill="#2e3234" d="M443,84.76H422.31l-.44-3.7a5.54,5.54,0,0,1,2.45-1.47V59.22a5.2,5.2,0,0,1-2.45-1.51l.44-3.7h20.32v7.75l-3.3.4a6.56,6.56,0,0,1-1.65-3.29h-6.95v7.66h6.2A5.11,5.11,0,0,1,438,64.66l2.81.31V72.9l-2.81.36a4.23,4.23,0,0,1-1.11-1.87h-6.2V79.9H438a6.6,6.6,0,0,1,1.65-3.3l3.3.4Z" />
          <path fill="#2e3234" d="M475.43,68c0-1.19.1-3,.31-5.53l.09-1.07-.45,0c-.68,2.52-1.28,4.53-1.78,6l-5.93,17.43h-5.92l-6.24-17.43a29.38,29.38,0,0,1-1.52-5l-.26-1-.45,0c.27,2.65.4,4.84.4,6.6V79.68a5.42,5.42,0,0,1,2.45,1.64l-.44,3.44H447l-.44-3.44A5.42,5.42,0,0,1,449,79.68V59.09a5.33,5.33,0,0,1-2.54-1.65l.44-3.43H457l6.51,18a21.77,21.77,0,0,1,1.6,5.39l.14.76h.62A19.37,19.37,0,0,1,467.5,72l6.19-18h10l.45,3.43a5.43,5.43,0,0,1-2.54,1.65V79.5A5.69,5.69,0,0,1,484,81.06l-.45,3.7H473.42L473,81.1a5.75,5.75,0,0,1,2.45-1.56Z" />
          <path fill="#2e3234" d="M501.27,58.87a12.16,12.16,0,0,0-3.16-.4,4,4,0,0,0-2.65.82,2.83,2.83,0,0,0-1,2.32,3.43,3.43,0,0,0,.76,2.34,6.52,6.52,0,0,0,3,1.47l4,1.16a11.17,11.17,0,0,1,5.1,2.91,8.1,8.1,0,0,1,1.9,5.82,8.68,8.68,0,0,1-3.28,7.17,12.52,12.52,0,0,1-8.13,2.63,23.14,23.14,0,0,1-5.5-.69,14.65,14.65,0,0,1-4.7-1.94v-1a17.49,17.49,0,0,1,.31-2.59,10.78,10.78,0,0,1,.89-3H493a9.22,9.22,0,0,1,.85,3.7,9.26,9.26,0,0,0,3.63.66,5.58,5.58,0,0,0,3.41-1,3.26,3.26,0,0,0,1.34-2.8,6.14,6.14,0,0,0-.14-1.41,2.6,2.6,0,0,0-.82-1.2,5.11,5.11,0,0,0-2-1l-4.59-1.33q-7-2-7-8.92a8,8,0,0,1,2.94-6.48,11.67,11.67,0,0,1,7.69-2.43,21.7,21.7,0,0,1,9.29,2.14v1a19.09,19.09,0,0,1-.31,2.58,10.83,10.83,0,0,1-.9,3h-4.23A7,7,0,0,1,501.27,58.87Z" />
        </g>
      </g>
    </svg>
  );
}
