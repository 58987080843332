import { createGlobalStyle } from "styled-components";
import { normalize, darken } from "polished";

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  @import url('https://fonts.googleapis.com/css?family=Patua+One&display=swap');
  
  body {
    font-family: ${props => props.theme.fonts.body};
    line-height: 1.5;
    color: #444;
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;

    &:hover {
      color: ${props => darken(0.2, props.theme.colors.primary)};
      text-decoration: underline;
    }
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Patua One', ${props => props.theme.fonts.heading};
    font-weight: 400;
    color: #333;
  }
`;

export default GlobalStyle