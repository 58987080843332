import { Link as GatsbyLink } from "gatsby";
import React from "react";
import styled from "styled-components";

import Logo from "./logo"

export default function Header ({ siteTitle }) {
  return (
    <StyledHeader>
      <Brand>
        <GatsbyLink to="/"><Logo /></GatsbyLink>
      </Brand>
      <HeaderNavigation>
        <NavLink to="/about/">About</NavLink>
        <NavLink to="/render-types/">Render Types</NavLink>
        <NavLink to="/external-wall-insulation/">EWI</NavLink>
        <NavLink to="/render-suppliers/">Render Suppliers</NavLink>
        <NavLink to="/contact/">Contact Us</NavLink>
      </HeaderNavigation>
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  ${props => props.theme.mq.md} {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Brand = styled.div`
  margin-right: ${props => props.theme.space[2]};
  padding: ${props => props.theme.space[2]} 0;
`;

const HeaderNavigation = styled.nav`
  ${props => props.theme.mq.md} {
    margin-left: auto;
  }
`;

const NavLink = styled(GatsbyLink).attrs({
  activeClassName: "active",
  partiallyActive: true
})`
  color: #777;
  font-size: ${props => props.theme.fontSizes[0]};
  font-weight: bold;
  text-decoration: none;

  & + & {
    margin-left: ${props => props.theme.space[3]};
  }

  :hover {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }

  &.active {
    border-bottom-color: black;
    border-bottom-style: solid;
    border-bottom-width: 0.125rem;
    color: black;

    :hover {
      border-bottom-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.primary};
    }
  }
`;
