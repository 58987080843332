import React from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";

const Footer = ({ siteTitle }) => (
  <Wrapper>
    <Navigation>
      <FooterWidget>
        <Heading>Links</Heading>
        <LinksList>
          <li>
            <FooterLink to="/">Homepage</FooterLink>
          </li>
          <li>
            <FooterLink to="/about/">About Us</FooterLink>
          </li>
          <li>
            <FooterLink to="/gallery/">Gallery</FooterLink>
          </li>
          <li>
            <FooterLink to="/contact/">Contact Us</FooterLink>
          </li>
          <li>
            <a href="/sitemap.xml">Sitemap</a>
          </li>
        </LinksList>
      </FooterWidget>
      <FooterWidget>
        <Heading>Types of Render</Heading>
        <LinksList>
          <li>
            <FooterLink to="/render-types/acrylic-render/">Acrylic Render</FooterLink>
          </li>
          <li>
            <FooterLink to="/render-types/base-coats-and-adhesives/">
              Base Coat &amp; Adhesives
            </FooterLink>
          </li>
          <li>
            <FooterLink to="/render-types/brick-effect-render/">
              Brick Effect Render
            </FooterLink>
          </li>
          <li>
            <FooterLink to="/render-types/lime-render/">Lime Render</FooterLink>
          </li>
          <li>
            <FooterLink to="/render-types/pebble-dash/">Pebble Dash</FooterLink>
          </li>
          <li>
            <FooterLink to="/render-types/polymer-render/">Polymer Render</FooterLink>
          </li>
          <li>
            <FooterLink to="/render-types/sand-and-cement/">Sand &amp; Cement</FooterLink>
          </li>
          <li>
            <FooterLink to="/render-types/scratch-render/">Scratch Render</FooterLink>
          </li>
          <li>
            <FooterLink to="/external-wall-insulation/">
              External Wall Insulation
            </FooterLink>
          </li>
        </LinksList>
      </FooterWidget>
      <FooterWidget>
        <Heading>Render Suppliers</Heading>
        <LinksList>
          <li>
            <FooterLink to="/render-suppliers/k-rend/">K Rend</FooterLink>
          </li>
          <li>
            <FooterLink to="/render-suppliers/wetherby/">Wetherby</FooterLink>
          </li>
          <li>
            <FooterLink to="/render-suppliers/weber/">Weber</FooterLink>
          </li>
          <li>
            <FooterLink to="/render-suppliers/parex/">Parex</FooterLink>
          </li>
        </LinksList>
      </FooterWidget>
    </Navigation>

    <Footnote>
      © {new Date().getFullYear()} {siteTitle}
    </Footnote>
  </Wrapper>
);

const Wrapper = styled.footer`
  color: #777;
  font-size: ${props => props.theme.fontSizes[0]};
  margin-top: ${props => props.theme.space[4]};
`;

const Navigation = styled.nav`
  ${props => props.theme.mq.md} {
    display: flex;
    justify-content: space-between;
  }
`;

const FooterWidget = styled.div`
  ${props => props.theme.mq.md} {

  }
`

const Heading = styled.h4`
  color: black;
  font-size: ${props => props.theme.fontSizes[1]};
  margin: 0;
`;

const LinksList = styled.ul`
  list-style-type: none;
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li {
    margin-bottom: ${props => props.theme.space[1]};
  }
`;

const FooterLink = styled(GatsbyLink)`
  text-decoration: none;
`;

const Footnote = styled.div`
  padding-bottom: ${props => props.theme.space[2]};
  padding-top: ${props => props.theme.space[2]};
  text-decoration: none;
`;

export default Footer;
