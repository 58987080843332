import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled, { ThemeProvider } from "styled-components";

import GlobalStyle from "./global-style";
import Header from "./header";
import Footer from "./footer";
import theme from "../utils/theme";

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Main>{children}</Main>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </Container>
    </ThemeProvider>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: ${props => props.theme.breakpoints[2]};
  min-height: 100vh;
  padding: ${props => props.theme.space[3]};
`;

export const Main = styled.main`
  flex: 1 1 auto;
`;
